<template>
  <div>
    <div class="main-title">邮件附件</div>
    <div class="content-list-page page-content">
      <div class="margin-b-sm margin-l-sm">
        <span class="fl-l margin-l-sm">
          <el-input v-model="searchData.name" placeholder="附件名称" />
        </span>

        <span class="margin-l-sm fl-l">
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <router-link class="router-link" to="/accessory/add">
            <el-button size="small" type="primary">新增</el-button>
          </router-link>
        </span>
        <div class="table-content margin-t-sm">
          <el-table
            ref="singleTable"
            v-loading="loading"
            :data="tableData"
            :header-cell-style="{ padding: 0 }"
            style="width: 100%; background: #fff"
          >
            <el-table-column
              align="center"
              label="ID"
              property="id"
              width="80"
            />

            <el-table-column align="center" label="附件名称" property="name" />
            <el-table-column align="center" label="大小" property="size">
              <template slot-scope="scope">
                <span>{{ unitConversion(scope.row.size) }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="扩展名" property="ext" />
            <el-table-column
              align="center"
              label="上传者"
              property="user_name"
            />
            <el-table-column
              align="center"
              label="创建时间"
              property="created_at"
            />
            <el-table-column
              align="center"
              label="更新时间"
              property="updated_at"
            />

            <el-table-column label="操作">
              <template slot-scope="scope">
                <span class="margin-r-sm">
                  <el-link
                    slot="reference"
                    type="primary"
                    @click="editAimActivity(scope.row.id)"
                    >编辑
                  </el-link>
                </span>

                <span>
                  <el-popconfirm
                    icon="el-icon-info"
                    icon-color="red"
                    title="删除后将不可恢复，确定删除吗？"
                    @confirm="deleted(scope.row.id)"
                  >
                    <el-link slot="reference" type="primary">删除</el-link>
                  </el-popconfirm>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="table-batch margin-l-sm">
          <span class="fl-l">
            <el-pagination
              :current-page="page"
              :page-size="pageSize"
              :page-sizes="[10, 20]"
              :total="total"
              background
              layout="total, sizes, prev, pager, next, jumper"
              small
              @size-change="setPageSize"
              @current-change="setPage"
            >
            </el-pagination>
          </span>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "@/js/config";

export default {
  data() {
    return {
      //图片前缀
      path: config.imageUrl,
      searchData: {},
      loading: false,
      //分页
      page: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions("mailbox", ["attachmentFileList", "delAttachmentFile"]),
    unitConversion(size) {
      return (size / 1024 / 1024).toFixed(2) + "M";
    },
    async deleted(id) {
      try {
        const { res_info } = await this.delAttachmentFile({ id });
        if (res_info !== "ok") return;
        this.$message.success("删除成功");
      } finally {
        await this.getList();
      }
    },
    editAimActivity(id) {
      this.$router.push("/accessory/edit/" + id);
    },
    search() {
      this.page = 1;
      this.getList();
    },
    //分页
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let from = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.attachmentFileList(from);
      this.tableData = data.list;
      this.total = data.total;
      this.loading = false;
    },
  },
};
</script>